import React from 'react'
import { Link, navigate, graphql } from 'gatsby'

// Components
import Button from '../components/button'
import Layout from '../components/layout'
import SEO from '../components/seo'

import '../styles/pages/jobs.scss'

const HeroSection = ({ image }) => {
  return (
    <section className="bg-black hero-section relative flex items-end">
      <div className="z-10 relative max-w-screen-xl mx-auto w-full md:px-16 px-6 pb-9 md:pb-16">
        <h1 className="text-white m-0 font-normal text-6xl md:text-8xl">Jobs</h1>
      </div>
      <div className="img-wrapper w-full h-full absolute top-0 left-0 z-1">
        <img
          className="w-full h-full object-cover opacity-50"
          src={image.sizes.src}
          srcSet={image.sizes.srcSet}
          alt="Jobs"
        />
      </div>
    </section>
  )
}

const ContentSection = ({ data }) => {
  const { pageDescription, jobs } = data
  return (
    <section className="max-w-screen-xl mx-auto grid-cols-10 px-6 pt-10 md:grid md:p-20 content-section">
      <div className="col-start-1 col-end-6 lg:col-end-5 mb-12 md:mb-0">
        <h2 className="font-normal title text-tuya-slate">Current Openings</h2>
        <hr className="border-tuya-yellow w-32 mb-8" />
        <p
          style={{ lineHeight: 1.88, maxWidth: 369 }}
          className="text-tuya-dark text-base font-light whitespace-pre-line"
        >
          {pageDescription}
        </p>
      </div>
      {jobs.length === 0 ? (
        <p className="lg:col-start-6 lg:col-end-11 list-none md:col-start-8 md:col-end-11 mb-0 flex flex-wrap">
          No available positions at the moment.
        </p>
      ) : (
        <ul className="list-none mb-0 flex flex-wrap md:mt-4 md:col-start-8 md:col-end-11 lg:col-start-6 lg:col-end-11 ">
          {jobs.map((vacency, i) => (
            <li
              key={vacency.id}
              className="ml-7 text-lg mb-8 text-tuya-dark w-full md:ml-0 lg:w-1/2 lg:flex-grow "
            >
              {' '}
              <Link to={`/jobs/${vacency.title.toLowerCase().split(' ').join('-')}`}>
                {vacency.title}{' '}
                <hr
                  style={{
                    background: '#e8ebef',
                    marginTop: 12,
                    maxWidth: 200,
                  }}
                  className={`${i >= jobs.length - 1 ? 'md:hidden' : ''} ${
                    i >= jobs.length - 2 ? 'lg:hidden' : ''
                  }`}
                />
              </Link>
            </li>
          ))}
        </ul>
      )}
    </section>
  )
}

const OverlaySuccess = () => (
  <div
    style={{ backgroundColor: 'rgba(38, 47, 50, .6)' }}
    className="fixed h-full w-full z-50 top-0 left-0 flex items-center justify-center"
  >
    <div style={{ width: 400, height: 300 }} className="bg-white p-5">
      <div className="text-right mb-7">
        <button onClick={() => navigate('.', { state: { isSuccess: false }, replace: true })}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <g fill="none" fillRule="evenodd">
              <g fill="#262F32">
                <g>
                  <g>
                    <path
                      d="M-2.462 7.385L18.462 7.385 18.462 8.692 -2.462 8.692z"
                      transform="translate(-884 -270) translate(520 250) translate(364 20) rotate(-45 8 8.038)"
                    />
                    <path
                      d="M-2.462 7.385L18.462 7.385 18.462 8.692 -2.462 8.692z"
                      transform="translate(-884 -270) translate(520 250) translate(364 20) rotate(45 8 8.038)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </button>
      </div>
      <hr style={{ width: 120, borderColor: '#f9d16d' }} className="mx-auto mb-6" />
      <p style={{ maxWidth: 300 }} className="mx-auto text-center text-lg mb-10">
        Thanks for applying at Tuya Restaurants. Our team will get back to you shortly!
      </p>
      <div
        style={{
          width: 200,
        }}
        className="mx-auto relative"
      >
        <Button
          onClick={() => navigate('.', { state: { isSuccess: false }, replace: true })}
          isDark
          noArrow
          width="100%"
        >
          OK
        </Button>
      </div>
    </div>
  </div>
)
const Jobs = ({ data: graphqlData, location }) => {
  const { heroImage, ...data } = graphqlData.datoCmsJob
  return (
    <Layout className="jobs-page" pathname={location.pathname}>
      <SEO title="Jobs" />
      <HeroSection image={heroImage} />
      <ContentSection data={data} />
      {location && location.state && location.state.isSuccess && (
        <OverlaySuccess isSuccess={location.state.isSuccess} />
      )}
    </Layout>
  )
}

export const query = graphql`
  query {
    datoCmsJob {
      pageDescription
      heroImage {
        sizes {
          srcSet
          src
        }
      }
      jobs {
        id
        title
      }
    }
  }
`

export default Jobs
